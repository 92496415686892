/**
 * This file is injected as the first thing in the webpack bundle (see next.config.js) and
 * adds polyfills for older browsers.
 */
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill')
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill')
}

if (!Intl.DisplayNames) {
  require('@formatjs/intl-getcanonicallocales/polyfill')
  require('@formatjs/intl-locale/polyfill')
  require('@formatjs/intl-displaynames/polyfill')
  // temporary fix for a failing polyfil in the `render.ts` file to get tests to pass
  require('@formatjs/intl-displaynames/locale-data/en')
  require('@formatjs/intl-displaynames/locale-data/zh')
}

/**
 * IE11 support for DOM_NODE.remove()
 * User in components/Chat
 * https://stackoverflow.com/a/66097554/4289267
 */
;(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode.removeChild(this)
      },
    })
  })
})([Element.prototype, CharacterData.prototype, DocumentType.prototype])

/**
 * IE11 support for NodeList.forEach()
 * https://gist.github.com/bob-lee/e7520bfcdac266e5490f40c2759cc955
 */
if (
  typeof window !== 'undefined' &&
  'NodeList' in window &&
  !NodeList.prototype.forEach
) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this)
    }
  }
}
